.worksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1170px;
  margin: 50px auto 0;
}

.worksHook {
  line-height: 1.2;
  max-width: 1000px;
  margin: auto;
  text-align: center;
  font-size: 64px;
  font-weight: 800;
  font-family: Alegreya Sans;
}

.contentCardsContainer {
  display: grid;
  column-gap: 20px;
  margin-top: 80px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.persuasionContentContainer {
  display: grid;
  column-gap: 20px;
  margin-top: 80px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
}

.contentPlatformContainer {
  display: grid;
  column-gap: 10px;
  margin-top: 80px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 908px) {

  .contentCardsContainer {
    display: grid;
    row-gap: 20px;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: unset;
    margin: 0 10px;
  }
  
  .worksHook {
    font-size: var(--h1-font-size-medium-screen);
  }
}

@media (max-width: 750px) {

  .worksHook {
    font-size: var(--h1-font-size-small-screen);
  }

}

@media (max-width: 430px) {
  .worksHook {
    font-size: var(--h1-font-size-phone-screen);
    margin-bottom: 10px;
  }

}