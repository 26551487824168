h1 {
  font-size: 72px;
  font-weight: 1000;
  margin: unset;
  text-align: center;
  font-family: Alegreya Sans;
}

.attentionHookContainer {
  max-width: 1170px;
  margin: 170px auto 20px;
  line-height: 1.2;
}

.hookDescription {
  max-width: 1000px;
  text-align: center;
  font-size: var(--text-font-size-computer-screen);
  margin: 20px auto 32px;
  color: var(--dull-gold);
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  font-size: 18px;
  width: 140px;
  height: 50px;
  margin:  0 auto;
  border-radius: 24px;
  transition: 0.3s;
}

.buttonContainer > a {
  font-weight: 600;
}

.buttonContainer:hover {
  scale: 1.1;
  cursor: pointer;
}

.bounceAnimation {
  animation: bounce 1.2s forwards;
}

@keyframes bounce {
  0% {
    transform: translate(0, -150px)
  }

  50% {
    transform: translate(0, 30px)
    scale(1.03)
  }

  100% {
    transform: translate(0, 0px)
    scale(1)
  }
}

.fadein {
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
  transform: translate(0, 20px);
}

.fadein.hookVisible {
  opacity: 1;
  transform: translate(0, 0);
}

@media (max-width: 908px) {
  h1 {
    font-size: var(--h1-font-size-medium-screen);
  }
}

@media (max-width: 750px) {
  .attentionHookContainer {
    margin: 90px auto 20px;
  }

  h1 {
    font-size: var(--h1-font-size-small-screen);
  }
  .hookDescription {
    font-size: 18px;
  }
}

@media (max-width: 430px) {
  h1 {
    font-size: var(--h1-font-size-phone-screen);
  }

  .hookDescription {
    font-size: var(--text-font-size-phone-screen);
  }
}