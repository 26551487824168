a {
  text-decoration: none;
  color: black;
}

.navbarContainer {
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1170px;
  height: 80px;
  margin: 12px auto 0;
  padding: 0 2em;
  background-color: var(--white);
  border-radius: 14px;
  z-index: 10;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
}

.navLeft > a {
  font-family: "Alegreya Sans", sans-serif;
  font-size: 34px;
  font-weight: 1000;
  transition: 0.2s;
}

.navLeft > a:hover {
  opacity: 0.8;
}

.navRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.navRight > a {
  font-size: 18px;
  font-weight: 700;
  transition: 0.2s;
}

.navRight > a:hover {
  color: var(--secondary-color);
}

.bookMeeting {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 120px;
  border: none;
  background-color: var(--secondary-color);
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  font-family: "DM Sans";
}

.navRightHamburger {
  display: none;
}

@media (max-width: 750px) {
  .navRight {
    display: none;
  }

  .navRightHamburger {
    display: flex;
    justify-content: flex-end;
    /* position: relative; */
  }

  .navRightBurger {
    border: none;
    background-color: var(--white);
    cursor: pointer;
  }

  .navRightBurger > img {
    width: 32px;
    height: 32px;
  }

  .bookNowBurger {
    display: flex;
    justify-content: unset;
    padding: 1em 1em;
  }

  .dropdownMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    border-radius: 12px;
    background-color: var(--white);
    top: 85px;
    right: 0px;
    padding: 1em 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
    animation: toggleNav 0.2s forwards;
  }

  @keyframes toggleNav {
    0% {
      line-height: 0.2;
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      line-height: 1;
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dropdownMenu > a {
    font-size: 18px;
    font-weight: 600;
    padding: 1em 1em;
    transition: 0.2s;
    border-radius: 8px;
    font-size: 20px;
  }

}