.carouselContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* max-width: 1170px; */
  margin: 50px 0 0;
  /* padding: 0 2em; */
  gap: 30px;
}

.fa-solid {
  color: black;
}

.slide-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.center-slide {
  position: relative;
  display: flex !important;
  justify-content: center;
  scale: 1;
  opacity: 1;
  transition: 0.8s;
}

.not-center-slide {
  scale: 0.8;
  opacity: 0.5;
  transition: 0.8s;
  display: flex !important;
  justify-content: center;
}

.video-player {
  display: flex;
  justify-content: center;
  height: 510px;
  width: 290px;
  border-radius: 32px;
}

.testimonial-user-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.testimonial-user-info > span {
  font-weight: 600;
  font-size: 16px;
}

.testimonial-user-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background-color: var(--secondary-color);
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.next-arrow {
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
}

.prev-arrow {
  position: absolute;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
}

.phone-screen-next {
  right: 10px;
}
.phone-screen-prev {
  left: 10px;
}

@media (max-width: 900px) {
  .testimonial-user-info > span {
    font-weight: 600;
    font-size: 12px;
  }

  .testimonial-user-icon {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 430px) {
  .arrow-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border: none;
    background-color: var(--secondary-color);
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 10;
  }

  .testimonial-user-info > span {
    font-weight: 600;
    font-size: 12px;
  }

  .testimonial-user-icon {
    width: 30px;
    height: 30px;
  }

}
