:root {
  --white: #fff;
  --secondary-color: #FFD43B;
  --dull-gold: #71674d; 
  --h1-font-size-small-screen: 44px;
  --h1-font-size-medium-screen: 60px;
  --h1-font-size-phone-screen: 32px;
  --text-font-size-computer-screen: 18px;
  --text-font-size-phone-screen: 16px;
}

.visible {
  animation: fadeUp 1s ease-in-out forwards;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
