.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
  margin: 42px auto 30px;
  padding: 0 2em;
}

.footerBrand {
  font-size: 34px;
  font-weight: 1000;
  transition: 0.2s;
}