.ExampleCardContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1050px;
  min-height: 100px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
  border-radius: 46px;
  padding: 3.5em;
  margin: 50px auto 0;
}

.userInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.userInfoContainer > img {
  width: 200px;
  border-radius: 50%;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.instagram {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.instagram > img {
  width: 30px;
  height: 30px;
}

.instagram > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  transition: 0.3s;
}

.instagram > span:hover {
  scale: 1.05;
  color: var(--secondary-color);
}

.userInfo > span:nth-child(1) {
  font-size: 52px;
  font-weight: 800;
  margin-bottom: 2px;
}


.aronsMessage {
  text-align: center;
  font-size: var(--text-font-size-computer-screen);
}

.userStats {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.stats {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 16px;
}

.stats > img {
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
}

.statNumbers {
  display: flex;
  flex-direction: column;
}

.statNumbers > span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
}
.statNumbers > span:nth-child(2) {
  font-size: 14px;
}

@media (max-width: 750px) {

  .userInfoContainer > img {
    width: 150px;
  }

  .userInfoContainer {
    gap: 10px;
    align-items: flex-start;
  }

  .ExampleCardContainer {
    padding: 1.5em;
    margin: 26px 10px 0;
  }

  .aronsMessage {
    font-size: 20px;
  }

  .userStats {
    gap: 28px;
  }

}

@media (max-width: 430px) {

  .userInfoContainer > img {
    width: 90px;
    border-radius: 50%;
  }

  .stats > img {
    width: 24px;
    height: 24px;
  }

  .aronsMessage {
    text-align: center;
    font-size: var(--text-font-size-phone-screen);
  }

  .userInfo > span:nth-child(1) {
    font-size: var(--h1-font-size-phone-screen);
  }

  .instagram > img {
    width: 24px;
    height: 24px;
  }
  
  .instagram > span {
    font-size: var(--text-font-size-phone-screen);
  }
}