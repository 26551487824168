.statsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1170px;
  margin: 50px auto 0;
  padding: 0 2em;
  gap: 30px;
}

.statsHeader {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 64px;
  font-weight: 800;
  font-family: Alegreya Sans;
}

.statistics {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
}

.statistics > div {
  max-width: 300px;
}

.statistic > div:nth-child(1) {
  display: flex;
  justify-content: center;
  font-size: 42px;
  font-weight: 600;
}

.statistic > div:nth-child(2) {
  text-align: center;
  font-size: var(--text-font-size-computer-screen);
}


@media (max-width: 908px) {

  .statsHeader {
    font-size: var(--h1-font-size-medium-screen);
  }
}

@media (max-width: 750px) {

  .statsContainer {
    margin: 50px auto;
  }
  
  .statsHeader {
    font-size: var(--h1-font-size-small-screen);
  }

  .statistics {
    flex-direction: column;
    align-items: center;
  }

}

@media (max-width: 430px) {
  .statsHeader {
    font-size: var(--h1-font-size-phone-screen);
  }

  .statistic > div:nth-child(2) {
    font-size: var(--text-font-size-phone-screen);
  }

}