.contentCardContainer {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 30px;
  gap: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
}

.contentTitle {
  font-family: Alegreya Sans;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
  line-height: 1;
}

.contentCardContainer > img {
  width: 250px;
  height: 187.44px;
  align-self: center;
}


.contentMessage {
  text-align: center;
  font-family: 'DM Sans';
}

@media (max-width: 750px) {

  .ExampleCardContainer {
    padding: 1.5em;
    margin: 26px 10px 0;
  }

  .userMessage {
    font-size: 20px;
  }

  .contentCardContainer {
    padding: 80px 30px;
  }
  
}