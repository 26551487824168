.price {
  max-width: 1170px;
  margin: 0 auto 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notvisible {
  opacity: 0;
}

.pricingInformation {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 50px auto 0;
}

.pricingInformation > div:nth-child(1) {
  font-family: Alegreya Sans;
  font-size: 62px;
  font-weight: 800;
}

.informationContainer {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
}

.informationContainer > text {
  width: 50%;
  font-size: var(--text-font-size-computer-screen);
}

.pricingContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: stretch;
  gap: 10px;
}

.plans {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
  background-color: #f8f7ff;
  border-radius: 26px;
  padding: 32px;
  position: relative;
  height: 400px;
}

.mostPopular {
  background-color: var(--secondary-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
}

.mostPopularBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
  left: 160px;
  color: white;
  background-color: black;
  border-radius: 8px;
  font-weight: 600;
  width: 125px;
  height: 30px;
  transform: rotate(-13deg);
}

.maximumBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 35px;
  left: 200px;
  color: white;
  background-color: black;
  border-radius: 8px;
  font-weight: 600;
  width: 140px;
  height: 30px;
}

.plans > span:nth-child(1) {
  font-size: 32px;
  font-weight: 800;
}
.plans > span:nth-child(2) {
  font-size: 44px;
  font-weight: 1000;
  margin: 40px 0;
}
.plans > span:nth-child(3) {
  margin-bottom: 100px;
  font-size: 18px;
  font-weight: 600;
}

.serviceOffered {
  display: flex;
  gap: 16px;
}

.service {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 5px 2px;
}

.service > img {
  width: 20px;
}

.service > span {
  font-size: var(--text-font-size-computer-screen);
}

.servicebutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50px;
  border-radius: 30px;
  border: none;
  background-color: black;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}
.servicebutton:hover {
  scale: 1.1;
}

@media (max-width: 1022px) {

.maximumBadge {
  left: 30px;
  top: 80px;
}

}

@media (max-width: 750px) {

.informationContainer {
  flex-direction: column;
  margin: 0 10px;
}

.informationContainer > text {
  width: unset;
}

.maximumBadge {
  top: 35px;
  left: 200px;
}

.pricingContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.plans {
  border-radius: 26px;
  padding: 32px;
  margin: 0 10px;
  position: relative;
  height: unset;
}


}

@media (max-width: 430px) {

  .pricingInformation > div:nth-child(1) {
    margin-left: 10px;
    font-size: var(--h1-font-size-phone-screen);
  }

  .informationContainer {
    gap: 30px;
  }
  
  .informationContainer > text {
    font-size: var(--text-font-size-phone-screen);
  }

  .service > span {
    font-size: var(--text-font-size-phone-screen);
    font-weight: 500;
  }

  .maximumBadge {
    left: 30px;
    top: 80px;
  }

  .pricingInformation {
    gap: 20px;
  }

  .serviceOffered {
    margin-bottom: 20px;
  }
}