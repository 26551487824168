.contentPlatform {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: white;
  border-radius: 20px;
  padding: 28px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
}

.contentPlatform > span:nth-child(1) {
  font-size: 26px;
  font-weight: 800;
}
.contentPlatform > span:nth-child(2) {
  margin-top: 14px;
  font-size: 18px;
}