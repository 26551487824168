.extraInfoContainer {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  gap: 30px;
}

.extraInfoHeader {
  display: flex;
  flex-direction: column;
  font-size: 44px;
  width: 50%;
  font-weight: 800;
  gap: 20px;
  font-family: Alegreya Sans;
}

.extraInfoBlurb {
  font-size: var(--text-font-size-computer-screen);
  font-weight: 500;
}

.book {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  font-size: 18px;
  font-weight: 600;
  width: 140px;
  height: 50px;
  border-radius: 24px;
  border: none;
  transition: 0.3s;
  cursor: pointer;
}

.book:hover {
  scale: 1.1;
}

.extraInfo {
  display: flex;
  background-color: white;
  border-radius: 20px;
  padding: 26px;
  gap: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
}

.extraInfo > span {
  display: flex;
  justify-content: center;
  font-size: 62px;
  font-weight: 800;
}

.bold {
  display: flex;
  align-items: flex-start;
  gap: 14px;
}

.bold > img {
  width: 20px;
  height: 20px;
}

.referAFriend {
  border: 5px solid #f0b537 ;
  background-color: #f0b537;
}

.titleAndImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleAndImage > img {
  width: 50px;
  height: 50px;
}

.titleAndImage > span {
  font-size: 28px;
  max-width: 220px;
  font-weight: 600;
}

.infoMessage {
  margin-top: 20px;
  font-size: var(--text-font-size-computer-screen);
}

.actionButton {
  font-size: 16px;
  font-weight: 600;
  border: none;
  width: 10rem;
  height: 50px;
  border-radius: 30px;
  background-color: black;
  color: white;
  margin-top: 50px;
}

@media (max-width: 750px) {

  .extraInfo {
    margin: 0 10px;
  }

  .extraInfo > span {
    font-size: var(--h1-font-size-small-screen);
  }


}

@media (max-width: 430px) {
  
  .extraInfo > span {
    font-size: 30px;
  }

  .infoMessage {
    font-size: var(--text-font-size-phone-screen);
  }

  .extraInfo {
    flex-direction: column;
  }

  .extraInfoHeader {
    padding: 10px;
    width: unset;
    align-items: center;
  }

  .extraInfoHeader > div:nth-child(1) {
    text-align: center;
  }

}