.comparison {
  display: flex;
  flex-direction: column;
  max-width: 1170px;
  margin: 60px auto 0;
  gap: 40px;
}

.comparisonHeader {
  font-family: Alegreya Sans;
  font-size: 64px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  text-align: center;
}

.comparisonContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.comparisonDemonstration {
  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
  background-color: var(--white);
  max-width: 50%;
  width: 100%;
  justify-content: center;
  padding: 20px 0;
  border-radius: 12px;
}

.comparisonDemonstration > div:nth-child(1) {
  font-family: Alegreya Sans;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.comparisonList {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 12px;
  font-size: var(--text-font-size-computer-screen);
}

.comparisonData {
  display: flex;
  align-items: center;
  gap: 10px;
}

.iconSize {
  width: 26px;
  height: 26px;
}


@media (max-width: 908px) {
  .comparisonHeader {
    font-size: var(--h1-font-size-medium-screen);
  }

  .comparisonContainer {
    flex-direction: column;
    margin: 0 10px;

  }

  .comparisonDemonstration {
    max-width: unset;
    width: 100%;
    justify-content: center;
    padding: 20px 0;
    border-radius: 12px;
  }

}

@media (max-width: 430px) {

  .comparisonContainer {
    margin: 0 10px;
  }


  .comparisonHeader {
    font-size: var(--h1-font-size-small-screen);
  }

  .comparisonList {
    padding: 10px;
    font-size: var(--text-font-size-phone-screen);
  }

  .comparisonDemonstration > div:nth-child(1) {
    font-size: var(--h1-font-size-phone-screen);
  }

  .iconSize {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 430px) {
  .comparisonHeader {
    font-size: var(--h1-font-size-phone-screen);
  }

  .comparison {
    gap: 10px;
  }
}