.container {
  max-width: 1170px;
  width: 100%;
  margin: 50px auto 0;
}

.faqWrapper {
  border-radius: 40px;
}

.layoutGrid {
  display: flex;
  flex-direction: column;
}

.faqLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.faqRedirect {
  color: var(--secondary-color);
  text-decoration: underline;
}

.title {
  font-size: 46px;
  font-weight: 800;
  max-width: 260px;
  margin-top: 10px;
}

.faqContainer {
  overflow: hidden;
  margin-bottom: 60px;
}

.faq {
  overflow: hidden;
  padding: 8px;
  transition: 0.5s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 10px -10px;
  background-color: var(--white);
  border-radius: 14px;
  margin: 12px 0;
}

.close {
  height: 80px;
}

.faqToggle {
  display: flex;
  justify-content: space-between;
  height: 80px;
  font-size: 32px;
  font-weight: 800;
  cursor: pointer;
  padding: 0 20px;
}

.faqToggle > div:nth-child(1) {
  display: flex;
  align-items: center;
  max-width: 90%;
}

.faqAnswer {
  margin: 10px 20px;
  font-size: var(--text-font-size-computer-screen);
}

.open1 {
  height: 120px;
}
.open2 {
  height: 145px;
}
.open3 {
  height: 180px;
}
.open4 {
  height: 145px;
}

@media (max-width: 1100px) {
  .open1 {
    height: 145px;
  }
  .open4 {
    height: 165px;
  }
}
@media (max-width: 1050px) {
  .open3 {
    height: 200px;
  }

}

@media (max-width: 875px) {
  .open2 {
    height: 165px;
  }

  .open3{
    height: 200px;
  }
}

@media (max-width: 875px) {
    .open3 {
      height: 220px;
    }
}

@media (max-width: 750px) {
  .faqContainer {
    margin: 0 10px ;
  }

  .title {
    margin-left: 10px;
  }

  .faqToggle {
    font-size: 28px;
  }

  .open {
    height: 240px;
  }

  .open4 {
    height: 185px;
  }
}

@media (max-width: 675px) {
  .open3 {
    height: 240px;
  }
}
@media (max-width: 620px) {
  .open2 {
    height: 185px;
  }

  .open4 {
    height: 205px;
  }
}

@media (max-width: 605px) {
  .open1 {
    height: 165px;
  }
}

@media (max-width: 575px) {
  .open3 {
    height: 260px;
  }
}
@media (max-width: 515px) {
  .close {
    height: 70px;
  }

  .faqToggle {
    font-size: 22px;
  }

  .open2 {
    height: 220px;
  }

  .open4 {
    height: 240px;
  }
}
@media (max-width: 475px) {
  .open3 {
    height: 320px;
  }
  .open2 {
    height: 220px;
  }

  .open4 {
    height: 260px;
  }
}

@media (max-width: 441px) {
  .open1 {
    height: 185px;
  }


}

@media (max-width: 430px) {
  .faqToggle > div:nth-child(1) {
    font-size: var(--text-font-size-phone-screen);
  }

  .title {
    margin-left: 10px;
    font-size: var(--h1-font-size-phone-screen);
  }

  .close {
    height: 35px;
  }

  .faqToggle {
    height: unset;
  }

  .faqAnswer {
    font-size: var(--text-font-size-phone-screen);
  }

  .faqToggle > div:nth-child(1) {
    font-size: var(--text-font-size-phone-screen);
  }

  .title {
    margin-left: 10px;
    font-size: var(--h1-font-size-phone-screen);
  }

  .close {
    height: 30px;
  }

  .faqToggle {
    height: unset;
  }

  .faqAnswer {
    font-size: var(--text-font-size-phone-screen);
  }

  .open1 {
    height: 110px;
  }

  .open2 {
    height: 150px;
  }

  .open3 {
    height: 245px;
  }

  .open4 {
    height: 200px;
  }
}


/* @media (max-width: 390px) {
  
  .faqToggle > div:nth-child(1) {
    font-size: var(--text-font-size-phone-screen);
  }

  .title {
    margin-left: 10px;
    font-size: var(--h1-font-size-phone-screen);
  }

  .close {
    height: 35px;
  }

  .faqToggle {
    height: unset;
  }

  .faqAnswer {
    font-size: var(--text-font-size-phone-screen);
  }

  .open1 {
    height: 110px;
  }

  .open2 {
    height: 150px;
  }

  .open3 {
    height: 245px;
  }

  .open4 {
    height: 200px;
  }
} */