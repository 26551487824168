.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  border: 5px solid black;
  padding-bottom: 6em;
  border-radius: 46px;
  background-color: var(--white);
}

.cardContainer > h1 {
  max-width: 1000px;
  margin: 60px 20px 30px;
  font-size: 60px;
}

.cardContainer > span {
  font-size: 20px;
  font-weight: 500;
}

.steps {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  font-size: 20px;
}

.step {
  display: flex;
  align-items: center;
  gap: 10px;
}

.step > span:nth-child(1) {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6913;
  border-radius: 50%;
  color: var(--white);
}

.step > span:nth-child(2) {
  font-weight: 800;
}